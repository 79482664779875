import { Field, Formik } from 'formik'
import React from 'react'
import { Button, Form, FormGroup, Label, Modal, ModalBody, Table } from 'reactstrap'
import { customInputForm } from 'ui/common'

const IndoorMapDeviceModal = ({ device, modalOpen, toggle }) => {
    return (
        <Modal isOpen={modalOpen} toggle={toggle} centered>
            <ModalBody
                style={{
                    padding: '24px',
                    position: 'relative',
                    overflowY: 'auto',
                }}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/modal-icons/device-modal-icon.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex justify-content-start align-items-start">
                    <h5>
                        <strong className="modals-header">Device details</strong>
                    </h5>

                    <span
                        className="ml-2 d-flex justify-content-center align-items-center pull-top"
                        style={{
                            backgroundColor: '#F4F4F9',
                            padding: '0px 8px',
                            borderRadius: '16px',
                            color: '#3A3E66',
                        }}
                    >
                        {device?.status?.device_state
                            ? `${
                                  device?.status?.device_state?.charAt(0).toUpperCase() +
                                  device?.status?.device_state?.slice(1)
                              }`
                            : 'Offline'}
                    </span>
                </div>

                <Formik initialValues={device}>
                    {({ values }) => (
                        <Form>
                            <FormGroup>
                                <Label className="modals-labels" for="name">
                                    Name
                                </Label>
                                <Field
                                    className="modals-inputs"
                                    id="name"
                                    type="text"
                                    name="title"
                                    readOnly
                                    component={customInputForm}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label className="modals-labels" for="identifier">
                                    Identifier
                                </Label>
                                <Field
                                    className="modals-inputs"
                                    readOnly
                                    id="identifier"
                                    type="text"
                                    name="identifier"
                                    component={customInputForm}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label className="modals-labels" for="status">
                                    Status
                                </Label>
                                <Field
                                    className="modals-inputs"
                                    readOnly
                                    id="status"
                                    type="text"
                                    name="status.device_state"
                                    component={customInputForm}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label className="modals-labels" for="type">
                                    Model type
                                </Label>
                                <Field
                                    className="modals-inputs"
                                    readOnly
                                    id="type"
                                    type="text"
                                    name="model_type"
                                    component={customInputForm}
                                />
                            </FormGroup>
                            {device.states.length > 0 && (
                                <div>
                                    <Label className="modals-labels" for="definition">
                                        States
                                    </Label>

                                    <div
                                        className="big-org-container"
                                        style={{ padding: '0', marginTop: '4px' }}
                                    >
                                        <Table bordered className="my-custom-table border-0">
                                            <thead>
                                                <tr>
                                                    <th className="react-table-header">Title</th>
                                                    <th className="react-table-header">Payload</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {device.states.map((state, index) => (
                                                    <tr key={index}>
                                                        <td className="react-table-style">
                                                            {state.title}
                                                        </td>
                                                        <td
                                                            key={index}
                                                            className="react-table-style"
                                                        >
                                                            {state.payload}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            )}
                            {device.components.length > 0 && (
                                <div>
                                    <Label className="modals-labels" for="definition">
                                        Components
                                    </Label>

                                    <div
                                        className="big-org-container"
                                        style={{ padding: '0', marginTop: '4px' }}
                                    >
                                        <Table bordered className="my-custom-table border-0">
                                            <thead>
                                                <tr>
                                                    <th className="react-table-header">Title</th>
                                                    <th className="react-table-header">Value</th>
                                                    <th className="react-table-header">
                                                        Identifier
                                                    </th>
                                                    <th className="react-table-header">
                                                        OPC UA node id
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {device.components.map((component, index) => (
                                                    <tr key={index}>
                                                        <td className="react-table-style">
                                                            {component.name}
                                                        </td>
                                                        <td className="react-table-style">
                                                            {component.value}
                                                        </td>
                                                        <td className="react-table-style">
                                                            {component.identifier}
                                                        </td>
                                                        <td className="react-table-style">
                                                            {component.details.opc_ua_node_id}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            )}
                            <Button
                                onClick={toggle}
                                className="save-btn-modal modals-new-btns mt-2"
                                style={{ placeSelf: 'flex-end' }}
                            >
                                Close window
                            </Button>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default IndoorMapDeviceModal
