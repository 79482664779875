import React from 'react'
import { BiCurrentLocation } from 'react-icons/bi'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import { UncontrolledTooltip } from 'reactstrap'

const MapCoordinates = ({ Xcords, YCords, resolution, original_height, original_width }) => {
    return (
        <div className="ml-5 d-flex align-items-center justify-content-center">
            <BiCurrentLocation size={28} />
            <span>
                {' '}
                {isNaN(Xcords) ? 0 : Xcords}(m) / {isNaN(YCords) ? 0 : YCords}(m)
            </span>
            <HiOutlineInformationCircle size={18} className="mb-3" id="info" />
            <UncontrolledTooltip
                placement="right"
                target="info"
                style={{
                    maxWidth: '13rem',
                }}
                className="custom-map-tooltip"
            >
                <div>
                    Resolution: <small>{resolution}</small>-{' '}
                    <small>
                        {original_width} x {original_height}{' '}
                    </small>{' '}
                    Grid_size - <small className="px-2">{1} Meters</small>{' '}
                </div>
            </UncontrolledTooltip>
        </div>
    )
}

export default MapCoordinates
