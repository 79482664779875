export const actionTypes = {
    CREATE_TEAM: 'CREATE_TEAM',
    CREATE_TEAM_FAIL: 'CREATE_TEAM_FAIL',
    CREATE_TEAM_SUCCESS: 'CREATE_TEAM_SUCCESS',
    UPDATE_TEAM: 'UPDATE_TEAM',
    UPDATE_TEAM_FAIL: 'UPDATE_TEAM_FAIL',
    UPDATE_TEAM_SUCCESS: 'UPDATE_TEAM_SUCCESS',
    DELETE_TEAM: 'DELETE_TEAM',
    DELETE_TEAM_FAIL: 'DELETE_TEAM_FAIL',
    DELETE_TEAM_SUCCESS: 'DELETE_TEAM_SUCCESS',
    GET_TEAMS: 'GET_TEAMS',
    GET_TEAMS_FAIL: 'GET_TEAMS_FAIL',
    GET_TEAMS_SUCCESS: 'GET_TEAMS_SUCCESS',
    GET_TEAM: 'GET_TEAM',
    GET_TEAM_FAIL: 'GET_TEAM_FAIL',
    GET_TEAM_SUCCESS: 'GET_TEAM_SUCCESS',
    GET_TEAM_PIN: 'GET_TEAM_PIN',
    GET_TEAM_PIN_FAIL: 'GET_TEAM_PIN_FAIL',
    GET_TEAM_PIN_SUCCESS: 'GET_TEAM_PIN_SUCCESS',
    GET_TEAM_WEBHOOKS: 'GET_TEAM_WEBHOOKS',
    GET_TEAM_WEBHOOKS_FAIL: 'GET_TEAM_WEBHOOKS_FAIL',
    GET_TEAM_WEBHOOKS_SUCCESS: 'GET_TEAM_WEBHOOKS_SUCCESS',
    CREATE_TEAM_WEBHOOK: 'CREATE_TEAM_WEBHOOK',
    CREATE_TEAM_WEBHOOK_SUCCESS: 'CREATE_TEAM_WEBHOOK_SUCCESS',
    CREATE_TEAM_WEBHOOK_FAIL: 'CREATE_TEAM_WEBHOOK_FAIL',
    UPDATE_TEAM_WEBHOOK: 'UPDATE_TEAM_WEBHOOK',
    UPDATE_TEAM_WEBHOOK_SUCCESS: 'UPDATE_TEAM_WEBHOOK_SUCCESS',
    UPDATE_TEAM_WEBHOOK_FAIL: 'UPDATE_TEAM_WEBHOOK_FAIL',
    DELETE_TEAM_WEBHOOK: 'DELETE_TEAM_WEBHOOK',
    DELETE_TEAM_WEBHOOK_SUCCESS: 'DELETE_TEAM_WEBHOOK_SUCCESS',
    DELETE_TEAM_WEBHOOK_FAIL: 'DELETE_TEAM_WEBHOOK_FAIL',
    GET_TEAM_EVENTS: 'GET_TEAM_EVENTS',
    GET_TEAM_EVENT_FAIL: 'GET_TEAM_EVENT_FAIL',
    GET_TEAM_EVENT_SUCCESS: 'GET_TEAM_EVENT_SUCCESS',
    ADD_IMAGE: 'ADD_IMAGE',
    ADD_IMAGE_SUCCESS: 'ADD_IMAGE_SUCCESS',
    ADD_IMAGE_FAIL: 'ADD_IMAGE_FAIL',
    GET_ALL_TEAM_MEMBERS: 'GET_ALL_TEAM_MEMBERS',
    GET_ALL_TEAM_MEMBERS_SUCCESS: 'GET_ALL_TEAM_MEMBERS_SUCCESS',
    GET_ALL_TEAM_MEMBERS_FAIL: 'GET_ALL_TEAM_MEMBERS_FAIL',
    UPDATE_TEAM_MEMBER: 'UPDATE_TEAM_MEMBER',
    UPDATE_TEAM_MEMBER_FAIL: 'UPDATE_TEAM_MEMBER_FAIL',
    UPDATE_TEAM_MEMBER_SUCCESS: 'UPDATE_TEAM_MEMBER_SUCCESS',
    UPDATE_PATH_PLANNING: 'UPDATE_PATH_PLANNING',
    UPDATE_PATH_PLANNING_FAIL: 'UPDATE_PATH_PLANNING_FAIL',
    UPDATE_PATH_PLANNING_SUCCESS: 'UPDATE_PATH_PLANNING_SUCCESS',
    GET_PATH: 'GET_PATH',
    GET_PATH_FAIL: 'GET_PATH_FAIL',
    GET_PATH_SUCCESS: 'GET_PATH_SUCCESS',
    REMOVE_TEAM_MEMBER: 'REMOVE_TEAM_MEMBER',
    REMOVE_TEAM_MEMBER_SUCCESS: 'REMOVE_TEAM_MEMBER_SUCCESS',
    REMOVE_TEAM_MEMBER_FAIL: 'REMOVE_TEAM_MEMBER_FAIL',
    CREATE_MEMBER: 'CREATE_MEMBER',
    CREATE_MEMBER_SUCCESS: 'CREATE_MEMBER_SUCCESS',
    CREATE_MEMBER_FAIL: 'CREATE_MEMBER_FAIL',
    CLEAR_TEXT: 'CLEAR_TEXT',
    NUKE_BUTTON: 'NUKE_BUTTON',
    NUKE_RESET: 'NUKE_RESET',
}

export function getTeamMembers(slug) {
    return {
        type: actionTypes.GET_ALL_TEAM_MEMBERS,
        payload: {
            request: {
                url: `api/teams/${slug}/memberships/`,
                method: 'GET',
            },
        },
    }
}

export function getTeam({ slug }) {
    return {
        type: actionTypes.GET_TEAM,
        payload: {
            request: {
                url: `api/teams/${slug}/?detailed=true`,
                method: 'GET',
            },
        },
    }
}
export function createMember({ slug, data }) {
    return {
        type: actionTypes.CREATE_MEMBER,
        payload: {
            request: {
                url: `api/teams/${slug}/memberships/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function getTeamPin({ uuid }) {
    return {
        type: actionTypes.GET_TEAM_PIN,
        payload: {
            request: {
                url: `api/teams/${uuid}/pin/`,
                method: 'GET',
            },
        },
    }
}

export function getTeams() {
    return {
        type: actionTypes.GET_TEAMS,
        payload: {
            request: {
                url: 'api/teams/',
                method: 'GET',
            },
        },
    }
}

export function createTeam({ slug, data }) {
    return {
        type: actionTypes.CREATE_TEAM,
        payload: {
            request: {
                url: `api/organizations/${slug}/teams/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function updateTeamAsJSON({ uuid, ...data }) {
    return {
        type: actionTypes.UPDATE_TEAM,
        payload: {
            request: {
                url: `api/teams/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function updateTeam({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_TEAM,
        payload: {
            request: {
                url: `api/teams/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function updateTeamMembership({ uuid, ...data }) {
    return {
        type: actionTypes.UPDATE_TEAM_MEMBER,
        payload: {
            request: {
                url: `api/teams/memberships/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function addImage({ uuid, data }) {
    return {
        type: actionTypes.ADD_IMAGE,
        payload: {
            request: {
                url: `api/teams/${uuid}/image/`,
                method: 'POST',
                data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        },
    }
}

export function deleteTeam({ uuid }) {
    return {
        type: actionTypes.DELETE_TEAM,
        payload: {
            request: {
                url: `api/teams/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function getWebhooks({ slug }) {
    return {
        type: actionTypes.GET_TEAM_WEBHOOKS,
        payload: {
            request: {
                url: `api/teams/${slug}/webhooks/`,
                method: 'GET',
            },
        },
    }
}

export function createWebhook({ slug, data }) {
    return {
        type: actionTypes.CREATE_TEAM_WEBHOOK,
        payload: {
            request: {
                url: `api/teams/${slug}/webhooks/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function updateWebhook({ uuid, ...data }) {
    return {
        type: actionTypes.UPDATE_TEAM_WEBHOOK,
        payload: {
            request: {
                url: `api/webhooks/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function deleteWebhook({ uuid }) {
    return {
        type: actionTypes.DELETE_TEAM_WEBHOOK,
        payload: {
            request: {
                url: `api/webhooks/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}
export function removeTeamMember({ uuid }) {
    return {
        type: actionTypes.REMOVE_TEAM_MEMBER,
        payload: {
            request: {
                url: `api/teams/memberships/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function getTeamEvents({ slug }) {
    return {
        type: actionTypes.GET_TEAM_EVENTS,
        payload: {
            request: {
                url: `api/teams/${slug}/events/`,
                method: 'GET',
            },
        },
    }
}

export function updatePathPlanning({ slug, data }) {
    return {
        type: actionTypes.UPDATE_PATH_PLANNING,
        payload: {
            request: {
                url: `api/teams/${slug}/settings/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function getUpdatedPathPlanning({ slug }) {
    return {
        type: actionTypes.GET_PATH,
        payload: {
            request: {
                url: `api/teams/${slug}/settings/`,
                method: 'GET',
            },
        },
    }
}

export function nukeFunc(slug) {
    return {
        type: actionTypes.NUKE_BUTTON,
        payload: {
            request: {
                url: `api/v2/teams/${slug}/stop/`,
                method: 'PATCH',
            },
        },
    }
}

export function nukeReset(slug) {
    return {
        type: actionTypes.NUKE_RESET,
        payload: {
            request: {
                url: `api/v2/teams/${slug}/reset/`,
                method: 'PATCH',
            },
        },
    }
}
