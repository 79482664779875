import React, { useState, Fragment } from 'react'
import { Card, CardBody, Col, Row, Fade, CardHeader, Badge, CardTitle } from 'reactstrap'
import cx from 'classnames'
import { useDispatch } from 'react-redux'
// import { AiFillRobot } from 'react-icons/ai'
import {
    deleteTaskV2,
    confirmTaskV2,
    updateTaskV2,
    pauseTask,
    resumeTask,
    updateAwaitTask,
} from 'features/dashboard/Task.actionTypes'
import { extractSubtaskInfo } from 'utils'
import { BiCircle, BiLoaderCircle } from 'react-icons/bi'
import { AiFillCheckCircle, AiOutlineNumber, AiOutlineRobot } from 'react-icons/ai'
import { BsInfoCircle } from 'react-icons/bs'
import TaskModalV2 from './TaskModalV2'
import TaskAwaitModal from './TaskAwaitModal'

const TaskDetailV2 = ({ task, robot, slug, setTaskHovered }) => {
    const dispatch = useDispatch()
    const [modal, setModal] = useState(false)
    const [awaitModal, setAwaitModal] = useState(false)

    const toggle = () => {
        setModal(!modal)
    }

    const toggleAwait = () => {
        setAwaitModal(!awaitModal)
    }

    const handleFail = ({ uuid }) => {
        dispatch(deleteTaskV2({ uuid }))
        toggle()
    }
    const handleSuccess = ({ uuid }) => dispatch(confirmTaskV2({ uuid, task }))

    const handleAwait = ({ uuid, data }) => {
        dispatch(updateAwaitTask({ uuid, data }))
        toggleAwait()
    }
    const seenTerminatedTask = ({ uuid }) => {
        dispatch(updateTaskV2({ uuid, data: { seen: true } }))
    }

    const canNotCancelTask = () => {
        return (
            task.status === 'failed' ||
            task.status === 'terminated' ||
            task.status === 'aborted' ||
            task.status === 'completed'
        )
    }

    const canCancelTask = () => !canNotCancelTask()

    const subtaskInfo = extractSubtaskInfo(task)
    // const created_at = new Date(task.metadata.created_at).toLocaleString()
    // const executed_at = task.executed_at
    //     ? ' - '.concat(new Date(task.metadata.executed_at).toLocaleString())
    //     : ''

    const renderVehicleBadge = (task) => {
        if (task.vehicle) {
            return (
                <p className=" text-left text-uppercase">
                    <Badge color=" text-black neutral-primary px-1 overflow-hidden">
                        <AiOutlineRobot />: {task.vehicle.verbose_name}
                    </Badge>
                </p>
            )
        }
        return null
    }
    return (
        <Fragment>
            <Card className="border shadow-sm text-left text-dark card-size mb-3 p-2 overflow-hidden">
                <CardHeader
                    className={cx('p-2 m-0 d-flex align-items-center', {
                        'bg-meili': robot !== '' && task.vehicle?.verbose_name === robot,
                    })}
                    style={{ borderBottom: '1px solid #E7E9EC' }}
                    onMouseEnter={() => setTaskHovered(task.vehicle?.verbose_name)}
                    onMouseLeave={() => setTaskHovered('')}
                >
                    {task.status === 'completed' ? (
                        <AiFillCheckCircle className="mr-2 text-success" />
                    ) : task.status === 'in_progress' ? (
                        <BiLoaderCircle className="mr-2 text-success" />
                    ) : task.status === 'paused' ? (
                        <BiLoaderCircle className="mr-2" />
                    ) : (
                        <BiCircle className="mr-2" />
                    )}
                    <strong
                        id="check-number"
                        className="overflow-hidden"
                        style={{ textOverflow: 'ellipsis', height: '1.2rem', whiteSpace: 'nowrap' }}
                    >
                        {task.number}
                    </strong>
                    {task.status === 'in_progress' && (
                        <img
                            src="/svgs/pause.svg"
                            style={{ margin: '0px 4px 0px auto', cursor: 'pointer' }}
                            width={16}
                            height={16}
                            alt="pause"
                            onClick={() => dispatch(pauseTask(task.uuid))}
                        />
                    )}
                    {task.status === 'paused' && (
                        <img
                            src="/svgs/resume.svg"
                            style={{ margin: '0px 4px 0px auto', cursor: 'pointer' }}
                            width={16}
                            height={16}
                            alt="resume"
                            onClick={() => dispatch(resumeTask(task.uuid))}
                        />
                    )}
                    <BsInfoCircle
                        className={cx('font-size-lg ml-auto info-icon rounded-circle')}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            toggle()
                        }}
                    />
                </CardHeader>
                <CardTitle className="d-flex justify-content-between p-0 m-0 px-2 card-fontsize-title">
                    {renderVehicleBadge(task)}

                    <Badge color="neutral-primary text-black text-right text-uppercase d-flex align-items-center justify-content-end">
                        <AiOutlineNumber color="outline-meili-light" /> : {' '}
                        <span>{subtaskInfo.totalLegs}</span>
                    </Badge>
                </CardTitle>
                <CardBody className="p-0 m-0 px-1 mt-2 add-scroll">
                    <Row>
                        <Col md={12}>
                            <div className={cx('ml-3 overflow-auto')}>
                                {task.subtasks?.map((sub, index) => (
                                    <div key={index}>
                                        <span
                                            className={cx(
                                                `${
                                                    sub.status === 'in_progress'
                                                        ? 'text-black'
                                                        : 'text-dark'
                                                } d-flex justify-content-start align-items-center`,
                                                {
                                                    blur:
                                                        index !== subtaskInfo.currentIndex &&
                                                        subtaskInfo.requireUserInput,
                                                }
                                            )}
                                        >
                                            <span className="mr-2">{index + 1}</span>
                                            {subtaskInfo.subtaskName[index]}
                                            {sub.status === 'completed' ? (
                                                <AiFillCheckCircle className="ml-2 text-success" />
                                            ) : sub.status === 'in_progress' ? (
                                                <BiLoaderCircle className="ml-2 text-success" />
                                            ) : sub.status === 'paused' ? (
                                                <BiLoaderCircle className="ml-2" />
                                            ) : sub.status === 'offline' ? (
                                                <BiCircle className="ml-2 text-grey" />
                                            ) : (
                                                <BiCircle className="ml-2" />
                                            )}
                                        </span>
                                        <div
                                            style={{ maxHeight: '100%' }}
                                            className={cx(' ml-3', {})}
                                        >
                                            {(task.status === 'await_abort' ||
                                                task.status === 'offline') &&
                                                index === subtaskInfo.currentIndex && (
                                                    <Fade
                                                        in={
                                                            task.status === 'await_abort' ||
                                                            task.status === 'offline'
                                                        }
                                                        tag="Button"
                                                        href="#"
                                                        onClick={toggleAwait}
                                                        size="sm"
                                                        className="w-75 rounded-top border-0 rounded-bottom text-white bg-danger"
                                                    >
                                                        Continue
                                                    </Fade>
                                                )}
                                            {sub.status !== 'completed' &&
                                                index === subtaskInfo.currentIndex &&
                                                subtaskInfo.requireUserInput && (
                                                    <Fade
                                                        in={subtaskInfo.requireUserInput}
                                                        tag="Button"
                                                        color="success"
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            handleSuccess(task)
                                                        }}
                                                        size="sm"
                                                        className=" w-75 bg-success rounded-top border-0 rounded-bottom text-white"
                                                    >
                                                        {subtaskInfo.totalLegs ===
                                                        subtaskInfo.currentIndex
                                                            ? 'Click to finish'
                                                            : 'Click to proceed'}
                                                    </Fade>
                                                )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <TaskAwaitModal
                task={task}
                modal={awaitModal}
                toggle={toggleAwait}
                handleAwait={handleAwait}
            />
            <TaskModalV2
                handleSuccess={handleSuccess}
                modal={modal}
                toggle={toggle}
                handleFail={handleFail}
                canCancelTask={canCancelTask}
                task={task}
                seenTerminatedTask={seenTerminatedTask}
            />
        </Fragment>
    )
}

export default TaskDetailV2
