import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, FormGroup, Label, UncontrolledTooltip } from 'reactstrap'
import { Formik } from 'formik'
import { HiOutlineTrash } from 'react-icons/hi'
import { selectors } from 'features/dashboard'
import { getZonePresets, updateZonePresets } from 'features/dashboard/Task.actionTypes'
import DropdownSelect from 'layout-components/Dropdown'

const EditActionZonePreset = ({ zone, toggle, handleDeleteModalShow }) => {
    const dispatch = useDispatch()
    const taskStore = useSelector(selectors.getTask)
    const [entryPreset, setEntryPreset] = useState(
        (
            zone?.entry_preset &&
            taskStore.presetsV2?.results?.find((preset) => preset.uuid === zone?.entry_preset)
        )?.title || ''
    )
    const [exitPreset, setExitPreset] = useState(
        (
            zone?.exit_preset &&
            taskStore.presetsV2?.results?.find((preset) => preset.uuid === zone?.exit_preset)
        )?.title || ''
    )

    const handleEdit = (values) => {
        const data = {
            uuid: values.uuid,
            entry_preset: values.entry_preset,
            exit_preset: values.exit_preset,
        }
        dispatch(updateZonePresets({ uuid: data.uuid, data })).then(({ error }) => {
            if (error) return
            dispatch(getZonePresets(data.uuid))
        })
        toggle()
    }

    if (taskStore.presetsV2.length === 0) return

    return (
        <>
            <div
                style={{ marginBottom: '24px' }}
                className={`d-flex w-100 justify-content-between align-items-start`}
            >
                <img
                    src="/svgs/map-editor/obstacles-icon.svg"
                    alt="obstacle-icon"
                    width="48px"
                    height="48px"
                />
                <img
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="obstacle-icon"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggle}
                />
            </div>
            <h5>
                <strong>Edit action zone preset</strong>
            </h5>
            <Formik initialValues={zone} onSubmit={handleEdit}>
                {({ values, handleSubmit, setFieldValue }) => (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                        }}
                    >
                        <FormGroup>
                            <Label className="modals-labels">Entry preset</Label>
                            <img
                                src="/svgs/trigger-actions/question-mark.svg"
                                alt="plus"
                                id="entryPreset"
                                width={16}
                                height={16}
                                style={{
                                    cursor: 'pointer',
                                    color: '#C2C5CE',
                                    marginLeft: '4px',
                                }}
                            />
                            <UncontrolledTooltip
                                placement="right"
                                target="entryPreset"
                                delay={{ show: 100, hide: 400 }}
                                className="custom-tooltip"
                            >
                                Attach a mission preset to be automatically initiate docking
                                sequence (entering the zone).
                            </UncontrolledTooltip>
                            <DropdownSelect
                                selected={entryPreset}
                                setSelected={setEntryPreset}
                                options={[
                                    { uuid: null, title: '' },
                                    ...taskStore.presetsV2?.results.map((preset) => preset),
                                ]}
                                setFieldValue={setFieldValue}
                                fieldValue="entry_preset"
                            />
                            {/* <Field
                                className="modals-inputs"
                                type="select"
                                component={customInputForm}
                                name="entry_preset"
                                value={values.entry_preset || ''}
                            >
                                <option value=""></option>
                                {taskStore.presetsV2?.results.map((preset) => (
                                    <option value={preset.uuid} key={preset.uuid}>
                                        {preset.title}
                                    </option>
                                ))}
                            </Field> */}
                        </FormGroup>
                        <FormGroup>
                            <Label className="modals-labels">Exit preset</Label>
                            <img
                                src="/svgs/trigger-actions/question-mark.svg"
                                alt="plus"
                                id="exitPreset"
                                width={16}
                                height={16}
                                style={{
                                    cursor: 'pointer',
                                    color: '#C2C5CE',
                                    marginLeft: '4px',
                                }}
                            />
                            <UncontrolledTooltip
                                placement="right"
                                target="exitPreset"
                                delay={{ show: 100, hide: 400 }}
                                className="custom-tooltip"
                            >
                                Attach a mission preset to be automatically initiate undocking
                                sequence (when moving out of the zone).
                            </UncontrolledTooltip>
                            <DropdownSelect
                                selected={exitPreset}
                                setSelected={setExitPreset}
                                options={[
                                    { uuid: null, title: '' },
                                    ...taskStore.presetsV2?.results.map((preset) => preset),
                                ]}
                                setFieldValue={setFieldValue}
                                fieldValue="exit_preset"
                            />
                        </FormGroup>
                        <div
                            style={{ marginTop: '24px' }}
                            className={`d-flex w-100 justify-content-between align-items-center`}
                        >
                            <Button
                                color="none"
                                onClick={() => handleDeleteModalShow(zone)}
                                className="modals-delete-btn"
                            >
                                <HiOutlineTrash size={20} className="mr-1" />
                                Delete
                            </Button>
                            <div>
                                <Button
                                    color="none"
                                    onClick={toggle}
                                    className="modals-cancel-btn new-modals-btn"
                                >
                                    Cancel
                                </Button>

                                <Button
                                    color="meili"
                                    className="modals-save-btn new-modals-btn"
                                    type="submit"
                                    disabled={JSON.stringify(zone) === JSON.stringify(values)}
                                >
                                    Edit zone
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default EditActionZonePreset
