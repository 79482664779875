import React from 'react'
import RemoveModalOrg from './RemoveModalOrg'
import VehiclesModal from './VehiclesModal'
import CategoriesModal from './CategoriesModal'
import DeviceModelsModal from './DeviceModelsModal'
import RosSetupAddModal from './RosSetupAddModal'
import RosSetupEditModal from './RosSetupEditModal'
import WebhookModal from './WebhookModal'
import MembersModal from './MembersModal'
import TeamsModal from './TeamsModal'
import TeamMembersModal from './TeamMembersModal'
import TeamSiteSettings from './TeamSiteSettingsModal'

const ManageOrganizationModals = ({
    slug,
    form,
    setForm,
    filters,
    modal,
    toggle,
    setTeam,
    dataOnClick,
    submitDelete,
    currentPageNumber,
}) => {
    switch (form) {
        case 'add-member':
        case 'edit-member':
            return (
                <MembersModal
                    slug={slug}
                    form={form}
                    modal={modal}
                    toggle={toggle}
                    setForm={setForm}
                    member={dataOnClick}
                />
            )
        case 'add-team':
        case 'edit-team':
            return (
                <TeamsModal
                    slug={slug}
                    form={form}
                    modal={modal}
                    toggle={toggle}
                    setForm={setForm}
                    team={dataOnClick}
                />
            )
        case 'edit-team-member':
            return (
                <TeamMembersModal
                    slug={slug}
                    form={form}
                    setForm={setForm}
                    modal={modal}
                    toggle={toggle}
                    setTeam={setTeam}
                    member={dataOnClick}
                />
            )

        case 'team-site-settings':
            return (
                <TeamSiteSettings
                    slug={slug}
                    form={form}
                    modal={modal}
                    toggle={toggle}
                    team={dataOnClick}
                />
            )
        case 'add-vehicle':
        case 'edit-vehicle':
            return (
                <VehiclesModal
                    slug={slug}
                    form={form}
                    modal={modal}
                    toggle={toggle}
                    filters={filters}
                    setForm={setForm}
                    vehicle={dataOnClick}
                    currentPageNumber={currentPageNumber}
                />
            )
        case 'add-category':
        case 'edit-category':
            return (
                <CategoriesModal
                    slug={slug}
                    form={form}
                    modal={modal}
                    toggle={toggle}
                    setForm={setForm}
                    category={dataOnClick}
                />
            )
        case 'add-device-model':
        case 'edit-device-model':
            return (
                <DeviceModelsModal
                    slug={slug}
                    form={form}
                    modal={modal}
                    toggle={toggle}
                    filters={filters}
                    setForm={setForm}
                    device={dataOnClick}
                    currentPageNumber={currentPageNumber}
                />
            )
        case 'add-webhook':
        case 'edit-webhook':
            return (
                <WebhookModal
                    slug={slug}
                    form={form}
                    modal={modal}
                    toggle={toggle}
                    setForm={setForm}
                    scope="organization"
                    webhook={dataOnClick}
                />
            )
        case 'add-setup':
            return (
                <RosSetupAddModal
                    slug={slug}
                    form={form}
                    modal={modal}
                    toggle={toggle}
                    filters={filters}
                    currentPageNumber={currentPageNumber}
                />
            )
        case 'edit-setup':
            return (
                <RosSetupEditModal
                    modal={modal}
                    toggle={toggle}
                    setForm={setForm}
                    setup={dataOnClick}
                />
            )
        case 'remove-team':
        case 'remove-setup':
        case 'remove-member':
        case 'remove-vehicle':
        case 'remove-webhook':
        case 'remove-category':
        case 'remove-team-member':
        case 'remove-device-model':
            return (
                <RemoveModalOrg
                    form={form}
                    modal={modal}
                    toggle={toggle}
                    dataOnClick={dataOnClick}
                    submitDelete={submitDelete}
                />
            )
        default:
            return
    }
}

export default ManageOrganizationModals
