export const actionTypes = {
    GET_TASK: 'GET_TASK',
    GET_TASK_SUCCESS: 'GET_TASK_SUCCESS',
    GET_TASK_FAIL: 'GET_TASK_FAIL',
    UPDATE_TASK: 'UPDATE_TASK',
    UPDATE_TASK_SUCCESS: 'UPDATE_TASK_SUCCESS',
    UPDATE_TASK_FAIL: 'UPDATE_TASK_FAIL',
    CONFIRM_TASK: 'CONFIRM_TASK',
    CONFIRM_TASK_SUCCESS: 'CONFIRM_TASK_SUCCESS',
    DELETE_TASK: 'DELETE_TASK',
    DELETE_TASK_SUCCESS: 'DELETE_TASK_SUCCESS',
    RUN_PRESET_TASK: 'RUN_PRESET_TASK',
    RUN_PRESET_TASK_SUCCESS: 'RUN_PRESET_TASK_SUCCESS',
    DELETE_PRESET_TASK: 'DELETE_PRESET_TASK',
    DELETE_PRESET_TASK_SUCCESS: 'DELETE_PRESET_TASK_SUCCESS',
    CREATE_PRESET_TASK: 'CREATE_PRESET_TASK',
    CREATE_PRESET_TASK_SUCCESS: 'CREATE_PRESET_TASK_SUCCESS',
    CREATE_PRESET_TASK_FAIL: 'CREATE_PRESET_TASK_FAIL',
    CREATE_ONETIME_TASK: 'CREATE_ONETIME_TASK',
    CREATE_ONETIME_TASK_SUCCESS: 'CREATE_ONETIME_TASK_SUCCESS',
    CREATE_ONETIME_TASK_FAIL: 'CREATE_ONETIME_TASK_FAIL',
    UPDATE_TASK_FROM_WEBHOOK: 'UPDATE_TASK_FROM_WEBHOOK',
    CONFIRM_ABORTED_TASK: 'CONFIRM_ABORTED_TASK',
    UPDATE_SEEN_TASK: 'UPDATE_SEEN_TASK',
    UPDATE_SEEN_TASK_SUCCESS: 'UPDATE_SEEN_TASK_SUCCESS',
    PAUSE_TASK: 'PAUSE_TASK',
    PAUSE_TASK_SUCCESS: 'PAUSE_TASK_SUCCESS',
    RESUME_TASK: 'RESUME_TASK',
    RESUME_TASK_SUCCESS: 'RESUME_TASK_SUCCESS',
    CANCEL_ALL_TASK: 'CANCEL_ALL_TASK',
    CANCEL_ALL_TASK_SUCCESS: 'CANCEL_ALL_TASK_SUCCESS',
    GET_TASK_DEFINITIONS: 'GET_TASK_DEFINITIONS',
    GET_TASK_DEFINITIONS_SUCCESS: 'GET_TASK_DEFINITIONS_SUCCESS',
    GET_TASK_DEFINITIONS_FAIL: 'GET_TASK_DEFINITIONS_FAIL',
    CREATE_TASK_DEFINITIONS: 'CREATE_TASK_DEFINITIONS',
    CREATE_TASK_DEFINITIONS_SUCCESS: 'CREATE_TASK_DEFINITIONS_SUCCESS',
    CREATE_TASK_DEFINITIONS_FAIL: 'CREATE_TASK_DEFINITIONS_FAIL',
    DELETE_TASK_DEFINITIONS: 'DELETE_TASK_DEFINITIONS',
    DELETE_TASK_DEFINITIONS_SUCCESS: 'DELETE_TASK_DEFINITIONS_SUCCESS',
    DELETE_TASK_DEFINITIONS_FAIL: 'DELETE_TASK_DEFINITIONS_FAIL',
    UPDATE_TASK_DEFINITIONS: 'UPDATE_TASK_DEFINITIONS',
    UPDATE_TASK_DEFINITIONS_SUCCESS: 'UPDATE_TASK_DEFINITIONS_SUCCESS',
    UPDATE_TASK_DEFINITIONS_FAIL: 'UPDATE_TASK_DEFINITIONS_FAIL',
    CREATE_NEW_TASK: 'CREATE_NEW_TASK',
    CREATE_NEW_TASK_SUCCESS: 'CREATE_NEW_TASK_SUCCESS',
    CREATE_NEW_TASK_FAIL: 'CREATE_NEW_TASK_FAIL',
    CREATE_NEW_PRESET: 'CREATE_NEW_PRESET',
    CREATE_NEW_PRESET_SUCCESS: 'CREATE_NEW_PRESET_SUCCESS',
    CREATE_NEW_PRESET_FAIL: 'CREATE_NEW_PRESET_FAIL',
    GET_FORM_VALUES: 'GET_FORM_VALUES',
    GET_FORM_VALUES_SUCCESS: 'GET_FORM_VALUES_SUCCESS',
    GET_FORM_VALUES_FAIL: 'GET_FORM_VALUES_FAIL',
    UPDATE_FORM_VALUES: 'UPDATE_FORM_VALUES',
    CLEAN_FORM_VALUES: 'CLEAN_FORM_VALUES',
    RUN_PRESET_TASK_V2: 'RUN_PRESET_TASK_V2',
    RUN_PRESET_TASK_V2_SUCCESS: 'RUN_PRESET_TASK_V2_SUCCESS',
    GET_PRESET_TASKS_V2: 'GET_PRESET_TASKS_V2',
    GET_PRESET_TASKS_V2_SUCCESS: 'GET_PRESET_TASKS_V2_SUCCESS',
    GET_PRESET_V2: 'GET_PRESET_V2',
    GET_PRESET_V2_SUCCESS: 'GET_PRESET_V2_SUCCESS',
    GET_TASKS_V2: 'GET_TASKS_V2',
    GET_TASKS_V2_SUCCESS: 'GET_TASKS_V2_SUCCESS',
    GET_TASKS_ALL: 'GET_TASKS_ALL',
    GET_TASKS_ALL_SUCCESS: 'GET_TASKS_ALL_SUCCESS',
    UPDATE_TASK_V2: 'UPDATE_TASK_V2',
    UPDATE_TASK_V2_SUCCESS: 'UPDATE_TASK_V2_SUCCESS',
    UPDATE_TASK_V2_FAIL: 'UPDATE_TASK_V2_FAIL',
    DELETE_TASK_V2: 'DELETE_TASK_V2',
    DELETE_TASK_V2_SUCCESS: 'DELETE_TASK_V2_SUCCESS',
    CONFIRM_TASK_V2: 'CONFIRM_TASK_V2',
    CONFIRM_TASK_V2_SUCCESS: 'CONFIRM_TASK_V2_SUCCESS',
    DELETE_PRESET_TASK_V2: 'DELETE_PRESET_TASK_V2',
    DELETE_PRESET_TASK_V2_SUCCESS: 'DELETE_PRESET_TASK_V2_SUCCESS',
    UPDATE_PRESET_TASK_V2: 'UPDATE_PRESET_TASK_V2',
    UPDATE_PRESET_TASK_V2_SUCCESS: 'UPDATE_PRESET_TASK_V2_SUCCESS',
    UPDATE_TASK_FROM_WEBHOOK_V2: 'UPDATE_TASK_FROM_WEBHOOK_V2',
    GET_SCHEDULE_TASKS_V2: 'GET_SCHEDULE_TASKS_V2',
    GET_SCHEDULE_TASKS_V2_SUCCESS: 'GET_SCHEDULE_TASKS_V2_SUCCESS',
    GET_SCHEDULE_TASKS_V2_FAIL: 'GET_SCHEDULE_TASKS_V2_FAIL',
    DELETE_SCHEDULE_TASK_V2: 'DELETE_SCHEDULE_TASK_V2',
    DELETE_SCHEDULE_TASK_V2_SUCCESS: 'DELETE_SCHEDULE_TASK_V2_SUCCESS',
    UPDATE_SCHEDULE_TASK_V2: 'UPDATE_SCHEDULE_TASK_V2',
    UPDATE_SCHEDULE_TASK_V2_SUCCESS: 'UPDATE_SCHEDULE_TASK_V2_SUCCESS',
    CREATE_SCHEDULE_TASK_V2: 'CREATE_SCHEDULE_TASK_V2',
    CREATE_SCHEDULE_TASK_V2_SUCCESS: 'CREATE_SCHEDULE_TASK_V2_SUCCESS',
    CREATE_SCHEDULE_TASK_V2_FAIL: 'CREATE_SCHEDULE_TASK_V2_FAIL',
    GET_ACTIONS_PRESETS: 'GET_ACTIONS_PRESETS',
    GET_ACTIONS_PRESETS_SUCCESS: 'GET_ACTIONS_PRESETS_SUCCESS',
    GET_ACTIONS_PRESETS_FAIL: 'GET_ACTIONS_PRESETS_FAIL',
    CREATE_ACTIONS_PRESETS: 'CREATE_ACTIONS_PRESETS',
    CREATE_ACTIONS_PRESETS_SUCCESS: 'CREATE_ACTIONS_PRESETS_SUCCESS',
    CREATE_ACTIONS_PRESETS_FAIL: 'CREATE_ACTIONS_PRESETS_FAIL',
    UPDATE_ACTIONS_PRESETS: 'UPDATE_ACTIONS_PRESETS',
    UPDATE_ACTIONS_PRESETS_SUCCESS: 'UPDATE_ACTIONS_PRESETS_SUCCESS',
    UPDATE_ACTIONS_PRESETS_FAIL: 'UPDATE_ACTIONS_PRESETS_FAIL',
    DELETE_ACTIONS_PRESETS: 'DELETE_ACTIONS_PRESETS',
    DELETE_ACTIONS_PRESETS_SUCCESS: 'DELETE_ACTIONS_PRESETS_SUCCESS',
    DELETE_ACTIONS_PRESETS_FAIL: 'DELETE_ACTIONS_PRESETS_FAIL',
    GET_ZONE_PRESETS: 'GET_ZONE_PRESETS',
    GET_ZONE_PRESETS_SUCCESS: 'GET_ZONE_PRESETS_SUCCESS',
    GET_ZONE_PRESETS_FAIL: 'GET_ZONE_PRESETS_FAIL',
    UPDATE_ZONE_PRESETS: 'UPDATE_ZONE_PRESETS',
    UPDATE_ZONE_PRESETS_SUCCESS: 'UPDATE_ZONE_PRESETS_SUCCESS',
    UPDATE_ZONE_PRESETS_FAIL: 'UPDATE_ZONE_PRESETS_FAIL',
    RESET_PRESET_TASKS_V2: 'RESET_PRESET_TASKS_V2',
    RESET_ACTIONS_PRESETS: 'RESET_ACTIONS_PRESETS',
}

export function cancelAllTask({ slug }) {
    return {
        type: actionTypes.CANCEL_ALL_TASK,
        payload: {
            request: {
                url: `/api/teams/${slug}/tasks/all/`,
                method: 'DELETE',
                data: { slug },
            },
        },
    }
}

export function runPresetTask({ uuid, ...data }) {
    return {
        type: actionTypes.RUN_PRESET_TASK,
        payload: {
            request: {
                url: `/api/tasks/presets/${uuid}/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function createPresetTask({ slug, data }) {
    return {
        type: actionTypes.CREATE_PRESET_TASK,
        payload: {
            request: {
                url: `/api/teams/${slug}/tasks/presets/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function createOneTimeTask({ slug, ...data }) {
    return {
        type: actionTypes.CREATE_ONETIME_TASK,
        payload: {
            request: {
                url: `/api/teams/${slug}/tasks/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function updateTask({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_TASK,
        payload: {
            request: {
                url: `/api/tasks/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function getTask({ uuid }) {
    return {
        type: actionTypes.GET_TASK,
        payload: {
            request: {
                url: `/api/tasks/${uuid}/`,
                method: 'GET',
            },
        },
    }
}

export function deleteTask({ uuid }) {
    return {
        type: actionTypes.DELETE_TASK,
        payload: {
            request: {
                url: `/api/tasks/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function confirmTask({ uuid }) {
    return {
        type: actionTypes.CONFIRM_TASK,
        payload: {
            request: {
                url: `/api/tasks/${uuid}/`,
                method: 'POST',
            },
        },
    }
}

export function updateSeenTask({ slug, ...data }) {
    return {
        type: actionTypes.UPDATE_SEEN_TASK,
        payload: {
            request: {
                url: `/api/teams/${slug}/tasks/seen/`,
                method: 'POST',
                data,
            },
        },
    }
}

// -----------------------------------  V2  ------------------------------------------

export function pauseTask(uuid) {
    return {
        type: actionTypes.PAUSE_TASK,
        payload: {
            request: {
                url: `/api/v2/tasks/pause/${uuid}/`,
                method: 'POST',
            },
        },
    }
}

export function resumeTask(uuid) {
    return {
        type: actionTypes.RESUME_TASK,
        payload: {
            request: {
                url: `/api/v2/tasks/resume/${uuid}/`,
                method: 'POST',
            },
        },
    }
}

export function getTaskDefinitions(uuid) {
    return {
        type: actionTypes.GET_TASK_DEFINITIONS,
        payload: {
            request: {
                url: `/api/v2/teams/${uuid}/tasks/action-definitions/?expand=form`,
                method: 'GET',
            },
        },
    }
}

export function createTaskDefinitions({ slug, data }) {
    return {
        type: actionTypes.CREATE_TASK_DEFINITIONS,
        payload: {
            request: {
                url: `/api/v2/teams/${slug}/tasks/action-definitions/?expand=form`,
                method: 'POST',
                data,
            },
        },
    }
}

export function updateTaskDefinitions({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_TASK_DEFINITIONS,
        payload: {
            request: {
                url: `/api/v2/tasks/action-definitions/${uuid}/?expand=form`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function deleteTaskDefinitions(uuid) {
    return {
        type: actionTypes.DELETE_TASK_DEFINITIONS,
        payload: {
            request: {
                url: `/api/v2/tasks/action-definitions/${uuid}/?expand=form`,
                method: 'DELETE',
            },
        },
    }
}

export function createNewTask({ slug, data }) {
    return {
        type: actionTypes.CREATE_NEW_TASK,
        payload: {
            request: {
                url: `/api/v2/teams/${slug}/tasks/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function createNewPreset({ slug, data }) {
    return {
        type: actionTypes.CREATE_NEW_PRESET,
        payload: {
            request: {
                url: `/api/v2/teams/${slug}/tasks/presets/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function getTasksV2(slug) {
    return {
        type: actionTypes.GET_TASKS_V2,
        payload: {
            request: {
                url: `/api/v2/teams/${slug}/light-tasks/?time=today_or_unfinished`,
                method: 'GET',
            },
        },
    }
}

export function getTasksAll({ slug, range }) {
    return {
        type: actionTypes.GET_TASKS_ALL,
        payload: {
            request: {
                url: `/api/v2/teams/${slug}/light-tasks/?range=${range}`,
                method: 'GET',
            },
        },
    }
}
export function updateTaskV2({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_TASK_V2,
        payload: {
            request: {
                url: `/api/v2/tasks/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function updateAwaitTask({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_TASK_V2,
        payload: {
            request: {
                url: `/api/v2/tasks/aborted/${uuid}/decision/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function deleteTaskV2({ uuid }) {
    return {
        type: actionTypes.DELETE_TASK_V2,
        payload: {
            request: {
                url: `/api/v2/tasks/${uuid}/`,
                method: 'DELETE',
                data: { uuid },
            },
        },
    }
}

export function confirmTaskV2({ uuid, task }) {
    return {
        type: actionTypes.CONFIRM_TASK_V2,
        payload: {
            request: {
                url: `/api/v2/tasks/${uuid}/`,
                method: 'POST',
                task,
            },
        },
    }
}

export function getPresetTasksV2({ slug, page, filters }) {
    let queryParams = []

    // Add the page parameter if it exists
    if (page) {
        queryParams.push(`page=${page}`)
    }

    // Add each station_uuid as a separate parameter
    if (filters && filters.stations && filters.stations.length > 0) {
        filters.stations.forEach((uuid) => {
            queryParams.push(`station_uuid=${uuid}`)
        })
    }

    // Construct the query string
    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
    const url = `/api/v2/teams/${slug}/tasks/presets/${queryString}`

    return {
        type: actionTypes.GET_PRESET_TASKS_V2,
        payload: {
            request: {
                url: url,
                method: 'GET',
            },
        },
    }
}

export function resetPresetTasksV2() {
    return {
        type: actionTypes.RESET_PRESET_TASKS_V2,
    }
}

export function getPreset(uuid) {
    return {
        type: actionTypes.GET_PRESET_V2,
        payload: {
            request: {
                url: `/api/v2/tasks/presets/${uuid}/`,
                method: 'GET',
            },
        },
    }
}

export function runPresetTaskV2({ uuid, data }) {
    return {
        type: actionTypes.RUN_PRESET_TASK_V2,
        payload: {
            request: {
                url: `/api/v2/tasks/presets/${uuid}/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function updatePresetTaskV2({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_PRESET_TASK_V2,
        payload: {
            request: {
                url: `/api/v2/tasks/presets/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function deletePresetTaskV2({ uuid }) {
    return {
        type: actionTypes.DELETE_PRESET_TASK_V2,
        payload: {
            request: {
                url: `/api/v2/tasks/presets/${uuid}/`,
                method: 'DELETE',
                data: { uuid },
            },
        },
    }
}

export function getScheduleTasksV2({ slug }) {
    return {
        type: actionTypes.GET_SCHEDULE_TASKS_V2,
        payload: {
            request: {
                url: `/api/teams/${slug}/tasks/presets/scheduled/`,
                method: 'GET',
            },
        },
    }
}

export function createScheduleTaskV2({ slug, data, type }) {
    return {
        type: actionTypes.CREATE_SCHEDULE_TASK_V2,
        payload: {
            request: {
                url: `/api/v2/teams/${slug}/tasks/presets/scheduled/${type}/?expand=task_preset`,
                method: 'POST',
                data,
            },
        },
    }
}

export function deleteScheduleTaskV2({ uuid }) {
    return {
        type: actionTypes.DELETE_SCHEDULE_TASK_V2,
        payload: {
            request: {
                url: `/api/tasks/presets/scheduled/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}
export function updateScheduleTaskV2({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_SCHEDULE_TASK_V2,
        payload: {
            request: {
                url: `/api/tasks/presets/scheduled/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function getActionsPresets({ slug, filters }) {
    let queryParams = []
    if (filters && filters.stations && filters.stations.length > 0) {
        // Adding each station UUID as a separate parameter
        filters.stations.forEach((uuid) => {
            queryParams.push(`station_uuid=${uuid}`)
        })
    }
    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
    return {
        type: actionTypes.GET_ACTIONS_PRESETS,
        payload: {
            request: {
                url: `/api/v2/teams/${slug}/tasks/action-presets/${queryString}`,
                method: 'GET',
            },
        },
    }
}

export function resetActionsPresets() {
    return {
        type: actionTypes.RESET_ACTIONS_PRESETS,
    }
}

export function createActionsPresets({ slug, data }) {
    return {
        type: actionTypes.CREATE_ACTIONS_PRESETS,
        payload: {
            request: {
                url: `/api/v2/teams/${slug}/tasks/action-presets/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function updateActionsPresets({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_ACTIONS_PRESETS,
        payload: {
            request: {
                url: `/api/v2/tasks/action-presets/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function deleteActionsPresets(uuid) {
    return {
        type: actionTypes.DELETE_ACTIONS_PRESETS,
        payload: {
            request: {
                url: `/api/v2/tasks/action-presets/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function getZonePresets(uuid) {
    return {
        type: actionTypes.GET_ZONE_PRESETS,
        payload: {
            request: {
                url: `/api/zone-presets/${uuid}/`,
                method: 'GET',
            },
        },
    }
}

export function updateZonePresets({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_ZONE_PRESETS,
        payload: {
            request: {
                url: `/api/zone-presets/${uuid}/`,
                method: 'PUT',
                data,
            },
        },
    }
}

export function getFormValues(uuid) {
    return {
        type: actionTypes.GET_FORM_VALUES,
        payload: {
            request: {
                url: `/api/forms/${uuid}/`,
                method: 'GET',
            },
        },
    }
}

export function updateFormValues(index) {
    return {
        type: actionTypes.UPDATE_FORM_VALUES,
        payload: {
            data: index,
        },
    }
}

export function cleanFormValues() {
    return {
        type: actionTypes.CLEAN_FORM_VALUES,
        payload: {
            data: [],
        },
    }
}

export function updateTaskFromWebhookV2(data) {
    return {
        type: actionTypes.UPDATE_TASK_FROM_WEBHOOK_V2,
        payload: {
            data,
        },
    }
}
