import React from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import { Input, FormFeedback, Spinner, Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import { useFormikContext, ErrorMessage, getIn } from 'formik'
import { useEffect } from 'react'

export const CommonErrors = ({ extraFields = [], ...props }) => {
    const { isSubmitting, setTouched, touched } = useFormikContext()
    const allFieldsArray = ['detail', 'non_field_errors', ...extraFields]

    const availableFields = allFieldsArray.reduce((acc, field) => {
        acc[field] = true
        return acc
    }, {})

    useEffect(() => {
        if (isSubmitting) {
            setTouched({ ...availableFields, ...touched })
        }
    }, [isSubmitting]) // eslint-disable-line react-hooks/exhaustive-deps

    return allFieldsArray.map((field, index) => (
        <ErrorMessage
            name={field}
            key={index}
            className="text-danger text-center"
            component="p"
            {...props}
        />
    ))
}

export const customInputForm = ({ field, form: { touched, errors }, ...props }) => {
    const touch = getIn(touched, field.name)
    const error = getIn(errors, field.name)
    const freeToGo = field.name === 'custom_manufacturer' || field.name === 'vehicle_serial_number'

    if (props.id === 'Email body' || field.name.includes('email_body')) {
        return (
            <>
                <Input
                    type="textarea"
                    className="modals-inputs"
                    style={{ height: '200px' }}
                    {...field}
                />
                <FormFeedback>
                    <ErrorMessage name={field.name} />
                </FormFeedback>
            </>
        )
    }

    return (
        <>
            <Input invalid={!freeToGo && !!(touch && error)} {...field} {...props} />
            <FormFeedback>
                <ErrorMessage name={field.name} />
            </FormFeedback>
        </>
    )
}

export const Loading = ({ message, ...props }) => (
    <div
        className="loading-container text-center position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center"
        {...props}
    >
        <div className="loading-spinners mb-2">
            {Array(3)
                .fill(null)
                .map((_, index) => (
                    <Spinner key={index} type="grow" color="primary" className="loading-spinner" />
                ))}
        </div>
        <div className="loading-message ml-4">
            <h4>{message || 'Loading...'}</h4>
        </div>
    </div>
)

export const ConfirmAlert = ({ show, message, handleConfirm, handleCancel, type }) => {
    const container = document.createElement('div')
    document.body.appendChild(container)

    const closeModal = () => {
        ReactDOM.unmount(container)
    }

    createRoot(
        <Modal isOpen={show} fade={false} backdrop={'static'} centered={true}>
            <ModalBody>
                <h4>{message}</h4>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => {
                        if (handleCancel) handleCancel()
                        closeModal()
                    }}
                    color="secondary"
                    className="mr-2"
                >
                    No
                </Button>
                <Button
                    color={`${type}`}
                    onClick={() => {
                        handleConfirm()
                        closeModal()
                    }}
                >
                    Yes
                </Button>
            </ModalFooter>
        </Modal>,
        container
    )
}
