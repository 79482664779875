import { actionTypes } from './Vehicle.actionTypes'

export const vehiclesState = {
    status: 'loading',
    count: null,
    next: null,
    results: [],
    variables: [],
    vehicle: {},
    topics: [],
    categories: [],
    teamVehicle: [],
    teamVehiclesLight: [],
    wbvehicles: [],
    select_vehicles: [],
    team_vehicles_next: null,
    team_vehicles_count: null,
}

export const vehicleReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.GET_ORG_VEHICLES:
        case actionTypes.GET_TEAM_VEHICLES:
        case actionTypes.GET_TEAM_VEHICLES_LIGHT:
            return { ...state, status: 'loading' }

        case actionTypes.GET_VEHICLE_CAT:
        case actionTypes.GET_TOPICS:
        case actionTypes.GET_ORG_VEHICLES_SELECT:
            return { ...state, status: 'loading' }
        // Saving and Deleting Actions
        case actionTypes.CREATE_TOPIC:
        case actionTypes.CREATE_VEHICLE:
        case actionTypes.ADD_VEHICLE_TO_TEAM:
        case actionTypes.UPDATE_VEHICLE:
        case actionTypes.UPDATE_TOPIC:
            return { ...state, status: 'saving' }
        case actionTypes.DELETE_VEHICLE:
        case actionTypes.REMOVE_TOPIC:
            return { ...state, status: 'deleting' }

        // Loading and Error Actions
        case actionTypes.CREATE_TOPIC_FAIL:
        case actionTypes.GET_TEAM_VEHICLES_FAIL:
        case actionTypes.ADD_VEHICLE_TO_TEAM_FAIL:
        case actionTypes.DELETE_VEHICLE_FAIL:
        case actionTypes.UPDATE_TOPIC_FAIL:
        case actionTypes.GET_TOPICS_FAIL:
        case actionTypes.CREATE_VEHICLE_FAIL:
        case actionTypes.UPDATE_VEHICLE_FAIL:
        case actionTypes.GET_ORG_VEHICLES_FAIL:
        case actionTypes.GET_ORG_VEHICLES_SELECT_FAIL:
            return { ...state, status: 'error' }

        // Success Actions
        case actionTypes.GET_TOPICS_SUCCESS:
            return { ...state, status: 'loaded', topics: action.payload.data }
        case actionTypes.UPDATE_TOPIC_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                topics: state.topics.map((topic) =>
                    topic.uuid === action.payload.data.uuid ? action.payload.data : topic
                ),
            }
        case actionTypes.GET_VEHICLE_CAT_SUCCESS:
            return { ...state, status: 'loaded', categories: action.payload.data }
        case actionTypes.CREATE_TOPIC_SUCCESS:
            return { ...state, status: 'loaded', topics: [...state.topics, action.payload.data] }
        case actionTypes.CREATE_VEHICLE_SUCCESS:
            return { ...state, status: 'loaded', results: [...state.results, action.payload.data] }
        case actionTypes.CREATE_VARIABLE_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                variables: [...state?.variables, action.payload.data],
            }
        case actionTypes.DELETE_VEHICLE_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                results: state.results.filter(
                    (vehicle) => vehicle.uuid !== action.payload.data.uuid
                ),
            }
        case actionTypes.ADD_VEHICLE_TO_TEAM_SUCCESS:
            return { ...state, status: 'loaded' }
        case actionTypes.GET_ORG_VEHICLES_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                next: action.payload.data.next,
                count: action.payload.data.count,
                results: [
                    ...state.results,
                    ...action.payload.data.results.filter(
                        (item) =>
                            !state.results.some((existingItem) => existingItem.uuid === item.uuid)
                    ),
                ],
            }
        case actionTypes.GET_ORG_VEHICLES_SELECT_SUCCESS:
            return { ...state, status: 'loaded', select_vehicles: action.payload.data }
        case actionTypes.RESET_VEHICLES:
            return { ...state, status: 'loaded', results: [] }
        case actionTypes.UPDATE_VEHICLES_FROM_WEBHOOK:
            return { ...state, status: 'loaded', wbvehicles: action.payload.data }
        case actionTypes.GET_TEAM_VEHICLES_SUCCESS: {
            const data = action.payload.data

            const sortVehiclesByOnlineStatus = (vehicles) =>
                vehicles.sort((a, b) => (a.is_online === b.is_online ? 0 : a.is_online ? -1 : 1))

            let mergedVehicles

            if (Array.isArray(data)) {
                mergedVehicles = [...state.teamVehicle, ...data]
            } else {
                const { page, results } = data

                mergedVehicles =
                    page === 1
                        ? results
                        : [
                              ...state.teamVehicle,
                              ...results.filter(
                                  (veh) =>
                                      !state.teamVehicle.some(
                                          (existingVeh) => existingVeh.uuid === veh.uuid
                                      )
                              ),
                          ]
            }

            return {
                ...state,
                status: 'loaded',
                team_vehicles_next: data.next || null,
                team_vehicles_count: data.count || null,
                teamVehicle: sortVehiclesByOnlineStatus(mergedVehicles),
            }
        }
        case actionTypes.GET_TEAM_VEHICLES_LIGHT_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                teamVehiclesLight: action.payload.data,
            }
        case actionTypes.UPDATE_VEHICLE_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                results: state.results.map((vehicle) =>
                    vehicle.uuid === action.payload.data.uuid ? action.payload.data : vehicle
                ),
            }
        default:
            return state
    }
}
