import React from 'react'
import useImage from 'use-image'
import { Image as KonvaImage, Text } from 'react-konva'
import { stationIconSize } from './MapLayerHelpers'

const MapDeviceNew = ({ device, aoi, cursor, x, y, size }) => {
    const [deviceSelect] = useImage('/svgs/map-layer/device-select.svg')
    const [closedDoorSelect] = useImage('/svgs/map-layer/closed-door-select.svg')
    const [switchOnSelect] = useImage('/svgs/map-layer/switch-select.svg')
    const [elevatorSelect] = useImage('/svgs/map-layer/elevator-select.svg')
    const [trafficLightRedSelect] = useImage('/svgs/map-layer/traffic-light-red-select.svg')
    const { width, height } = aoi
    const biggerMapSize = 1000000

    const showImage = () => {
        switch (device.model_type) {
            case 'door':
                return closedDoorSelect
            case 'switch':
                return switchOnSelect
            case 'elevator':
                return elevatorSelect
            case 'traffic_light':
                return trafficLightRedSelect
            default:
                return deviceSelect
        }
    }

    if (!device) return

    return (
        <>
            <KonvaImage
                image={showImage()}
                width={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 2
                        : stationIconSize(device, width)
                }
                height={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 2
                        : stationIconSize(device, width)
                }
                offsetX={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 4
                        : stationIconSize(device, width) / 2
                }
                offsetY={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 4
                        : stationIconSize(device, width) / 2
                }
                x={x}
                y={y}
                onMouseEnter={(e) => {
                    const container = e.target.getStage().container()
                    container.style.cursor = 'grab'
                }}
                onMouseLeave={(e) => {
                    const container = e.target.getStage().container()
                    container.style.cursor = 'default'
                }}
            />
            <Text
                x={cursor && cursor[0]}
                y={cursor && cursor[1] / 1.5}
                align={'center'}
                text="Press ESC to cancel"
                fontSize={size}
                fontStyle="bold"
            ></Text>
        </>
    )
}

export default MapDeviceNew
