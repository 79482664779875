export const actionTypes = {
    GET_TEAM_VEHICLES: 'GET_TEAM_VEHICLES',
    GET_TEAM_VEHICLES_SUCCESS: 'GET_TEAM_VEHICLES_SUCCESS',
    GET_TEAM_VEHICLES_FAIL: 'GET_TEAM_VEHICLES_FAIL',
    GET_TEAM_VEHICLES_LIGHT: 'GET_TEAM_VEHICLES_LIGHT',
    GET_TEAM_VEHICLES_LIGHT_SUCCESS: 'GET_TEAM_VEHICLES_LIGHT_SUCCESS',
    GET_TEAM_VEHICLES_LIGHT_FAIL: 'GET_TEAM_VEHICLES_LIGHT_FAIL',
    GET_ORG_VEHICLES: 'GET_ORG_VEHICLES',
    GET_ORG_VEHICLES_SUCCESS: 'GET_ORG_VEHICLES_SUCCESS',
    GET_ORG_VEHICLES_FAIL: 'GET_ORG_VEHICLES_FAIL',
    RESET_VEHICLES: ' RESET_VEHICLES',
    DELETE_VEHICLE: 'DELETE_VEHICLE',
    DELETE_VEHICLE_SUCCESS: 'DELETE_VEHICLE_SUCCESS',
    DELETE_VEHICLE_FAIL: 'DELETE_VEHICLE_FAIL',
    ADD_VEHICLE_TO_TEAM: 'ADD_VEHICLE_TO_TEAM',
    ADD_VEHICLE_TO_TEAM_SUCCESS: 'ADD_VEHICLE_TO_TEAM_SUCCESS',
    ADD_VEHICLE_TO_TEAM_FAIL: 'ADD_VEHICLE_TO_TEAM_FAIL',
    UPDATE_VEHICLE: 'UPDATE_VEHICLE',
    UPDATE_VEHICLE_SUCCESS: 'UPDATE_VEHICLE_SUCCESS',
    UPDATE_VEHICLE_FAIL: 'UPDATE_VEHICLE_FAIL',
    CREATE_VEHICLE: 'CREATE_VEHICLE',
    CREATE_VEHICLE_SUCCESS: 'CREATE_VEHICLE_SUCCESS',
    CREATE_VEHICLE_FAIL: 'CREATE_VEHICLE_FAIL',
    GET_LIVE_TOPICS: 'GET_LIVE_TOPICS',
    GET_LIVE_TOPICS_FAIL: 'GET_LIVE_TOPICS_FAIL',
    GET_LIVE_TOPICS_SUCCESS: 'GET_LIVE_TOPICS_SUCCESS',
    GET_TOPICS: 'GET_TOPICS',
    GET_TOPICS_FAIL: 'GET_TOPICS_FAIL',
    GET_TOPICS_SUCCESS: 'GET_TOPICS_SUCCESS',
    UPDATE_TOPIC: 'UPDATE_TOPIC',
    UPDATE_TOPIC_FAIL: 'UPDATE_TOPIC_FAIL',
    UPDATE_TOPIC_SUCCESS: 'UPDATE_TOPIC_SUCCESS',
    CREATE_TOPIC: 'CREATE_TOPIC',
    CREATE_TOPIC_FAIL: 'CREATE_TOPIC_FAIL',
    CREATE_TOPIC_SUCCESS: 'CREATE_TOPIC_SUCCESS',
    REMOVE_TOPIC: 'REMOVE_TOPIC',
    REMOVE_TOPIC_FAIL: 'REMOVE_TOPIC_FAIL',
    REMOVE_TOPIC_SUCCESS: 'REMOVE_TOPIC_SUCCESS',
    GET_VEHICLE_CAT: 'GET_VEHICLE_CAT',
    GET_VEHICLE_CAT_FAIL: 'GET_VEHICLE_CAT_FAIL',
    GET_VEHICLE_CAT_SUCCESS: 'GET_VEHICLE_CAT_SUCCESS',
    UPDATE_VEHICLES_FROM_WEBHOOK: 'UPDATE_VEHICLES_FROM_WEBHOOK',
    GET_ORG_VEHICLES_SELECT: 'GET_ORG_VEHICLES_SELECT',
    GET_ORG_VEHICLES_SELECT_FAIL: 'GET_ORG_VEHICLES_SELECT_FAIL',
    GET_ORG_VEHICLES_SELECT_SUCCESS: 'GET_ORG_VEHICLES_SELECT_SUCCESS',
    REQUEST_FACTHSEET: 'REQUEST_FACTHSEET',
    ESTIMATED_POSE: 'ESTIMATED_POSE',
}

export function createTopic({ uuid, ...data }) {
    return {
        type: actionTypes.CREATE_TOPIC,
        payload: {
            request: {
                url: `api/vehicles/${uuid}/topics/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function removeTopic({ uuid }) {
    return {
        type: actionTypes.REMOVE_TOPIC,
        payload: {
            request: {
                url: `api/logs/topics/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function updateTopic({ uuid, ...data }) {
    return {
        type: actionTypes.UPDATE_TOPIC,
        payload: {
            request: {
                url: `api/logs/topics/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function updateLiveTopics({ uuid, ...data }) {
    return {
        type: actionTypes.UPDATE_TOPIC,
        payload: {
            request: {
                url: `api/vehicles/${uuid}/topics/live/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function getTopics({ uuid }) {
    return {
        type: actionTypes.GET_TOPICS,
        payload: {
            request: {
                url: `api/vehicles/${uuid}/topics/`,
                method: 'GET',
            },
        },
    }
}

export function getLiveTopics({ uuid }) {
    return {
        type: actionTypes.GET_LIVE_TOPICS,
        payload: {
            request: {
                url: `api/vehicles/${uuid}/topics/live/`,
                method: 'GET',
            },
        },
    }
}

export function getOrgVehiclePage({ slug, page, filters }) {
    let teamQuery = ''
    if (filters && filters.team && filters.team.length > 0) {
        teamQuery = `team_slug=${filters.team}`
    }

    let categoryQuery = ''
    if (filters && filters.category && filters.category.length > 0) {
        categoryQuery = `category=${filters.category}`
    }

    const statusQuery =
        filters && filters.status ? `status=${filters.status}` : ''

    const queryParams = [teamQuery, categoryQuery, statusQuery].filter(Boolean).join('&')

    const url = `api/organizations/${slug}/vehicles/?detailed=true${page ? `&page=${page}` : ''}${
        queryParams ? `&${queryParams}` : ''
    }`

    return {
        type: actionTypes.GET_ORG_VEHICLES,
        payload: {
            request: {
                url,
                method: 'GET',
            },
        },
    }
}

export function getOrgVehiclesSelect({ slug, text }) {
    return {
        type: actionTypes.GET_ORG_VEHICLES_SELECT,
        payload: {
            request: {
                url: `api/organizations/${slug}/vehicles/${text}`,
                method: 'GET',
            },
        },
    }
}

export const resetVehicles = () => {
    return { type: actionTypes.RESET_VEHICLES }
}

export function getVehicleCategories({ slug }) {
    return {
        type: actionTypes.GET_VEHICLE_CAT,
        payload: {
            request: {
                url: `api/teams/${slug}/vehicles/categories/?used=true`,
                method: 'GET',
            },
        },
    }
}

export function getTeamVehicles({ slug, text, page }) {
    return {
        type: actionTypes.GET_TEAM_VEHICLES,
        payload: {
            request: {
                url: `api/teams/${slug}/vehicles/?${text}${page ? `&page=${page}` : ''}`,
                method: 'GET',
            },
        },
    }
}

export function getTeamVehiclesLight({ slug }) {
    return {
        type: actionTypes.GET_TEAM_VEHICLES_LIGHT,
        payload: {
            request: {
                url: `api/teams/${slug}/light-vehicles/`,
                method: 'GET',
            },
        },
    }
}

export function deleteVehicle({ uuid }) {
    return {
        type: actionTypes.DELETE_VEHICLE,
        payload: {
            request: {
                url: `api/vehicles/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function updateVehicle({ uuid, ...data }) {
    return {
        type: actionTypes.UPDATE_VEHICLE,
        payload: {
            request: {
                url: `api/vehicles/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function removeVehicleFromOrg({ uuid }) {
    return {
        type: actionTypes.DELETE_VEHICLE,
        payload: {
            request: {
                url: `api/vehicles/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function removeVehicleFromTeam({ slug, ...data }) {
    return {
        type: actionTypes.DELETE_VEHICLE,
        payload: {
            request: {
                url: `api/teams/${slug}/vehicles/`,
                method: 'DELETE',
                data,
            },
        },
    }
}

export function addVehicleToTeam({ uuid, ...data }) {
    return {
        type: actionTypes.ADD_VEHICLE_TO_TEAM,
        payload: {
            request: {
                url: `api/vehicles/${uuid}/fleet/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function createVehicle({ slug, ...data }) {
    return {
        type: actionTypes.CREATE_VEHICLE,
        payload: {
            request: {
                url: `api/organizations/${slug}/vehicles/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function createTeamVehicle({ data, slug }) {
    return {
        type: actionTypes.CREATE_VEHICLE,
        payload: {
            request: {
                url: `api/teams/${slug}/vehicles/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function updateVehiclesFromWebkook(data) {
    return {
        type: actionTypes.UPDATE_VEHICLES_FROM_WEBHOOK,
        payload: {
            data,
        },
    }
}

export function requestFactSheet({ uuid }) {
    return {
        type: actionTypes.REQUEST_FACTHSEET,
        payload: {
            request: {
                url: `api/vehicles/factsheet/${uuid}/`,
                method: 'POST',
            },
        },
    }
}

export function sendEstimatedPose({ uuid, data }) {
    return {
        type: actionTypes.ESTIMATED_POSE,
        payload: {
            request: {
                url: `api/vehicles/${uuid}/initial-position/`,
                method: 'POST',
                data,
            },
        },
    }
}
