import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ErrorMessage, Field, Formik } from 'formik'
import { HiOutlineTrash } from 'react-icons/hi'
import {
    Button,
    Input,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    Table,
    UncontrolledDropdown,
} from 'reactstrap'
import { customInputForm } from 'ui/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { removeNullFromObj, useToggler } from 'utils'
import WebhookHeadersForm from './WebhookHeaderModal'
import {
    getWebhooks,
    createWebHook,
    updateWebHook,
} from 'features/dashboard/Organization.actionTypes'
import {
    createWebhook,
    getWebhooks as getTeamWebhooks,
    updateWebhook,
} from 'features/dashboard/Team.actionTypes'

const WebhookModal = ({
    slug,
    form,
    setForm,
    modal,
    toggle,
    scope,
    webhook = {},
    toggleRemove,
}) => {
    const dispatch = useDispatch()
    const [modalSmall, toggleSmall] = useToggler()
    const [scrollPosition, setScrollPosition] = useState('top')
    const [currentHeader, setCurrentHeader] = useState({})

    const initData = form === 'add-webhook' ? { headers: [], action_list: [] } : webhook

    useEffect(() => {
        if (!modalSmall) setCurrentHeader({})
    }, [modalSmall])

    const handleSubmit = (data) => {
        if (scope === 'organization') {
            if (form === 'add-webhook') {
                dispatch(createWebHook({ slug, data })).then(({ error }) => {
                    if (!error) {
                        dispatch(getWebhooks({ slug }))
                        toggle()
                    }
                })
            }

            if (form === 'edit-webhook') {
                const sortedData = removeNullFromObj(data)
                dispatch(updateWebHook(sortedData)).then(({ error }) => {
                    if (!error) {
                        dispatch(getWebhooks({ slug }))
                        toggle()
                    }
                })
            }
        }
        if (scope === 'team') {
            if (form === 'add-webhook') {
                dispatch(createWebhook({ slug, data })).then(({ error }) => {
                    if (!error) {
                        dispatch(getTeamWebhooks({ slug }))
                        toggle()
                    }
                })
            }

            if (form === 'edit-webhook') {
                const sortedData = removeNullFromObj(data)
                dispatch(updateWebhook(sortedData)).then(({ error }) => {
                    if (!error) {
                        dispatch(getTeamWebhooks({ slug }))
                        toggle()
                    }
                })
            }
        }
    }

    const HeadersList = ({ list }) => {
        if (!list || list.length === 0)
            return <p className="py-4">This webhook does not have any headers yet.</p>
        return (
            <div className="big-org-container" style={{ padding: '0', marginTop: '32px' }}>
                <Table bordered className="my-custom-table border-0">
                    <thead>
                        <tr>
                            <th className="react-table-header">Key</th>
                            <th className="react-table-header">Value</th>
                            <th className="react-table-header"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {list?.map((header, index) => (
                            <tr key={index}>
                                <td className="react-table-style">{header.key || '-'}</td>
                                <td className="react-table-style">{header.value || '-'}</td>
                                <td className="text-center react-table-style">
                                    <img
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        src="/svgs/trigger-actions/edit.svg"
                                        alt="edit"
                                        onClick={() => {
                                            setCurrentHeader({ header, index })
                                            toggleSmall()
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }

    const SelectedActions = ({ actions, onRemove }) => (
        <span>
            {actions.map((action, index) => (
                <div
                    className="d-flex justify-content-between align-items-center w-100 px-3 mt-2"
                    key={`action_${index}`}
                >
                    <span>{action}</span>
                    <Button onClick={() => onRemove(index)} color="link">
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                    <ErrorMessage
                        name={`action_list[${index}]`}
                        component="span"
                        className="text-danger"
                    />
                </div>
            ))}
        </span>
    )

    const ActionSelector = ({ onChange, actionList }) => {
        const actions = [
            'vehicle.connected',
            'vehicle.disconnected',
            'task.stuck',
            'task.timeout',
            'task.started',
            'task.completed',
            'task.failed',
            'task.disconnected',
            'fleet.connected',
            'fleet.disconnected',
        ]

        const filteredActions = actions.filter((action) => !actionList.includes(action))

        return (
            <UncontrolledDropdown color="meili">
                <DropdownToggle
                    style={{
                        border: '1px solid var(--grey-100, #e7e9ec)',
                        borderRadius: '8px',
                        padding: '8px 12px',
                        fontSize: '16px',
                        height: '40px',
                        width: '100%',
                        background: 'white',
                        color: '#3d414c',
                        textAlign: 'left',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        zIndex: 1000,
                    }}
                    className="modals-inputs w-100 d-flex align-items-center justify-content-between"
                >
                    <span>Select actions</span>
                    <FontAwesomeIcon icon={faChevronDown} />
                </DropdownToggle>
                <DropdownMenu
                    className="w-100"
                    style={{ zIndex: 1000, maxHeight: '200px', overflow: 'auto' }}
                >
                    {filteredActions.map((action) => (
                        <DropdownItem key={action} onClick={() => onChange(action)}>
                            {action}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }

    const renderArrow = (values) => {
        if (values.headers.length > 0 || values.action_list.length > 0) {
            if (scrollPosition === 'bottom') {
                return (
                    <img
                        src="/svgs/user-icon/arrow.svg"
                        alt="arrow"
                        className="rotated-up-arrow-modals"
                    />
                )
            } else if (scrollPosition === 'top') {
                return (
                    <img
                        src="/svgs/user-icon/arrow.svg"
                        alt="arrow"
                        className="rotated-down-arrow-modals"
                    />
                )
            } else {
                return (
                    <div className="d-flex">
                        <img
                            src="/svgs/user-icon/arrow.svg"
                            alt="arrow"
                            className="rotated-up-arrow-modals"
                        />
                        <img
                            src="/svgs/user-icon/arrow.svg"
                            alt="arrow"
                            className="rotated-down-arrow-modals"
                        />
                    </div>
                )
            }
        }
        return <div />
    }

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - 5 <= e.target.clientHeight
        const top = e.target.scrollTop === 0

        if (bottom) {
            setScrollPosition('bottom')
        } else if (top) {
            setScrollPosition('top')
        } else {
            setScrollPosition('middle')
        }
    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered className="vehicle-modal-org">
            <ModalBody
                style={{ padding: '24px 24px 0px 24px', position: 'relative', overflowY: 'auto' }}
                onScroll={handleScroll}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/trigger-actions/add-plus.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex flex-column">
                    <span className="org-modal-header">
                        {form === 'add-webhook' ? 'New webhook' : 'Webhook settings'}
                    </span>
                </div>
                <Formik initialValues={initData} onSubmit={handleSubmit}>
                    {({ values, setFieldValue, handleSubmit }) => (
                        <Form
                            style={{ marginTop: '14px' }}
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                        >
                            <FormGroup>
                                <Label className="modals-labels" for="url">
                                    URL *
                                </Label>
                                <Field
                                    className="modals-inputs"
                                    component={customInputForm}
                                    type="text"
                                    required
                                    value={values.url || ''}
                                    name="url"
                                    placeholder="Add URL"
                                />
                            </FormGroup>
                            <div className="my-4">
                                <div className="d-flex justify-content-between align-items-start">
                                    <span>Headers</span>
                                    <Button className="add-new-temp" onClick={toggleSmall}>
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            style={{ marginRight: '8px' }}
                                        />
                                        Add new
                                    </Button>
                                </div>
                                <HeadersList list={values.headers} />
                                <WebhookHeadersForm
                                    modal={modalSmall}
                                    toggle={toggleSmall}
                                    initialValues={currentHeader.header}
                                    handleAdd={(data) => {
                                        setFieldValue('headers', [...values.headers, data])
                                        toggleSmall()
                                    }}
                                    handleRemove={() => {
                                        const filteredHeaders = values.headers.filter(
                                            (_, i) => i !== currentHeader.index
                                        )
                                        setFieldValue('headers', filteredHeaders)
                                        toggleSmall()
                                    }}
                                    handleUpdate={(data) => {
                                        const updatedHeaders = [...values.headers]
                                        if (
                                            currentHeader.index < 0 ||
                                            currentHeader.index >= updatedHeaders.length
                                        )
                                            return

                                        updatedHeaders[currentHeader.index] = data
                                        setFieldValue('headers', updatedHeaders)
                                        toggleSmall()
                                    }}
                                />
                            </div>
                            <FormGroup>
                                <Label className="modals-labels">Actions</Label>
                                <ActionSelector
                                    actionList={values.action_list}
                                    onChange={(action) => {
                                        const isExisted = (action) =>
                                            values.action_list &&
                                            values.action_list.find((act) => act === action)
                                        if (isExisted(action)) return
                                        setFieldValue('action_list', [
                                            ...values.action_list,
                                            action,
                                        ])
                                    }}
                                />
                                <SelectedActions
                                    actions={values.action_list || []}
                                    onRemove={(index) =>
                                        setFieldValue(
                                            'action_list',
                                            values.action_list.filter((_, i) => i !== index)
                                        )
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormGroup check className="ml-2 mt-4">
                                    <Input
                                        type="checkbox"
                                        id="active"
                                        checked={values?.active}
                                        onChange={() => {
                                            setFieldValue('active', !values.active)
                                        }}
                                    />
                                    <Label check>Active</Label>
                                </FormGroup>
                            </FormGroup>
                            <div className="new-modal-footer">
                                {form === 'add-webhook' ? (
                                    renderArrow(values)
                                ) : (
                                    <Button
                                        color="none"
                                        onClick={() => {
                                            if (scope === 'organization') {
                                                setForm('remove-webhook')
                                            } else {
                                                toggleRemove()
                                            }
                                        }}
                                        className="modals-delete-btn"
                                    >
                                        <HiOutlineTrash size={20} className="mr-1" />
                                        Delete
                                    </Button>
                                )}
                                <div className="d-flex">
                                    <Button
                                        className="cancel-btn-modal modals-new-btns"
                                        onClick={() => {
                                            toggle()
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="save-btn-modal modals-new-btns"
                                        style={{ marginLeft: '12px' }}
                                        type="submit"
                                        disabled={
                                            form === 'edit-webhook' &&
                                            JSON.stringify(values) === JSON.stringify(webhook)
                                        }
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default WebhookModal
