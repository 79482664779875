import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Row,
    Col,
    Button,
    Container,
    Label,
    FormGroup,
    Input,
    Modal,
    UncontrolledTooltip,
} from 'reactstrap'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { selectors } from 'features/dashboard/'
import { getOrgTeams, getOrgs } from 'features/dashboard/Organization.actionTypes'
import cx from 'classnames'
import { getProfile, updateProfile } from 'features/user/actionTypes'
import { MdOutlineLogout } from 'react-icons/md'
import { getTeams } from 'features/dashboard/Team.actionTypes'
import { getVersion } from 'features/dashboard/Version.actionTypes'
import LoadingSpinner from 'components/utils/LoadingSpinner'

const TeamSelect = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { path } = useRouteMatch()
    const teams = useSelector(selectors.getTeam)
    const orgs = useSelector(selectors.getOrg)
    const user = useSelector(selectors.getUser)
    const [hasFoundOrg, setHasFoundOrg] = useState(false)
    const [prepareOrg, setPrepareOrg] = useState(null)
    const [selectOrg, setSelectOrg] = useState(false)
    const [autoSelect, setAutoSelect] = useState(false)
    const [prepareTeams, setPrepareTeams] = useState(null)
    const version = localStorage.getItem('version')
    const [versionModal, setVersionModal] = useState(false)

    let defaultOrg = orgs.list.filter(
        (org) => org.organization.uuid === user.profile.user_default_organization
    )[0]

    useEffect(() => {
        document.title = 'Meili Dashboard'
        dispatch(getOrgs())
        dispatch(getTeams())
        dispatch(getProfile())
        dispatch(getVersion()).then((res) => {
            if (version && version !== res.payload?.data) {
                // Show modal with button for hard refresh
                setVersionModal(true)
                localStorage.clear()
                localStorage.setItem('version', res.payload.data)
            }
            localStorage.setItem('version', res.payload.data)
        })
    }, [dispatch, version])

    useEffect(() => {
        if (!selectOrg && !hasFoundOrg) setPrepareOrg(null)
    }, [selectOrg, hasFoundOrg])

    useEffect(() => {
        if (prepareOrg) {
            localStorage.setItem('org', JSON.stringify(prepareOrg.organization))
        }
    }, [prepareOrg])

    // const navigateToOrg = (team = '', org) => {
    //     const queryParams = new URLSearchParams({
    //         team,
    //         org,
    //     })
    //     history.push({
    //         pathname: './organization/',
    //         search: queryParams.toString(),
    //     })
    // }

    useEffect(() => {
        if (orgs.list.length === 1) {
            setPrepareOrg(orgs.list[0])
            setHasFoundOrg(true)
            setAutoSelect(true)
            setSelectOrg(true)
        } else if (user.profile.user_default_organization !== null) {
            setPrepareOrg(defaultOrg)
            setHasFoundOrg(true)
            setAutoSelect(true)
            setSelectOrg(true)
        } else {
            setPrepareOrg()
            setHasFoundOrg(false)
            setAutoSelect(false)
            setSelectOrg(false)
        }
    }, [orgs.list, user.profile.user_default_organization, defaultOrg])

    const RenderTeams = ({ teams }) => {
        const teamsToRender = teams?.list.filter(
            (teamItem) => teamItem.organization.slug === prepareOrg?.organization.slug
        )
        return (
            <Row
                className="d-flex justify-content-center align-items-center mt-5"
                style={{ width: '500px' }}
            >
                {teamsToRender?.map((teamItem) => (
                    <Col key={teamItem.uuid} md={4} sm={4} xs={6} className="text-center">
                        {teamItem.image ? (
                            <img
                                className="team-img"
                                style={{ cursor: 'pointer' }}
                                src={teamItem?.image}
                                alt="logo"
                                onClick={() => history.push(`./${teamItem.slug}/`)}
                            />
                        ) : (
                            <img
                                className="team-img weight-400"
                                style={{ cursor: 'pointer' }}
                                src="/svgs/org-team.svg"
                                alt="logoimg"
                                onClick={() => history.push(`./${teamItem.slug}/`)}
                            ></img>
                        )}
                        <p
                            className="size-14 grey-900 weight-500 mt-3"
                            id={teamItem.slug}
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '200px', // Adjust as needed
                            }}
                        >
                            {teamItem.title}
                        </p>
                        {teamItem.title.length > 14 && (
                            <UncontrolledTooltip
                                placement="top"
                                style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
                                target={teamItem.slug}
                            >
                                {' '}
                                {teamItem.title}{' '}
                            </UncontrolledTooltip>
                        )}
                    </Col>
                ))}
            </Row>
        )
    }

    const RenderOrg = ({ orgs }) => (
        <Row className="d-flex justify-content-center align-items-center">
            {orgs.list.map((orgItem) => (
                <Col key={orgItem.organization?.uuid} md={4} sm={4} xs={6} className="text-center">
                    {orgItem.organization.image ? (
                        <img
                            className={cx('team-img', {
                                'custom-box-shadow team-img':
                                    prepareOrg?.organization?.slug === orgItem.organization?.slug,
                            })}
                            style={{ cursor: 'pointer' }}
                            src={orgItem.organization?.image}
                            alt="logo"
                            onClick={() => setPrepareOrg(orgItem)}
                        />
                    ) : (
                        <img
                            className={cx('team-img ', {
                                'custom-box-shadow team-img ':
                                    prepareOrg?.organization?.slug === orgItem.organization?.slug,
                            })}
                            style={{ cursor: 'pointer' }}
                            src="/svgs/org-team.svg"
                            alt="logoimg"
                            onClick={() => setPrepareOrg(orgItem)}
                        />
                    )}
                    <p
                        className={cx('weight-400 grey-900 size-14 mt-3', {
                            'weight-500 grey-900 size-14 mt-3':
                                prepareOrg?.organization?.slug === orgItem.organization?.slug,
                        })}
                        id={orgItem?.organization.slug}
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '200px', // Adjust as needed
                        }}
                        title={orgItem?.organization?.title}
                    >
                        {orgItem?.organization?.title}
                    </p>
                    {orgItem?.organization?.title.length > 14 && (
                        <UncontrolledTooltip
                            placement="top"
                            style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
                            target={orgItem?.organization.slug}
                        >
                            {' '}
                            {orgItem?.organization?.title}{' '}
                        </UncontrolledTooltip>
                    )}
                </Col>
            ))}
        </Row>
    )

    if (teams.status === 'loading')
        return (
            <div style={{ paddingTop: '50%' }}>
                <LoadingSpinner />
            </div>
        )

    return (
        <div>
            {selectOrg || hasFoundOrg ? (
                <Container
                    className="d-flex flex-column position-relative my-5 text-center mb-5"
                    style={{ height: '600px', width: '500px' }}
                >
                    <span className="text-center">
                        <img
                            width="176px"
                            height="64px"
                            className="text-logo text-white animated-text "
                            src={'/svgs/meili-text-logo.svg'}
                            alt="logo"
                        />
                    </span>
                    {teams.list.length === 0 ? (
                        <h4 className="mb-5 text-center">
                            You don't have any team, please ask your admin to assign you to a team
                        </h4>
                    ) : (
                        <div>
                            <h5 className="welcomStyle pt-5">Welcome back!</h5>
                            <span className="size-16 grey-600 mt-2 mb-5">
                                {' '}
                                Please choose your team
                            </span>
                            <RenderTeams teams={teams} />
                        </div>
                    )}

                    <Row className="mx-auto text-center pt-32 my-5">
                        <Col md={12} className="d-flex ">
                            <div
                                style={{ width: '212px', height: '40px', cursor: 'pointer' }}
                                className="bg-white view-setting mr-2 logout-border text-center d-flex align-items-center"
                                onClick={() => history.push('/logout/')}
                            >
                                <MdOutlineLogout
                                    style={{ marginRight: '8px' }}
                                    size="28"
                                ></MdOutlineLogout>
                                <span> Log out</span>
                            </div>
                            <Button
                                style={{ width: '240px', height: '40px' }}
                                className="save-btn-modal modals-new-btns"
                                color="meili"
                                onClick={() => {
                                    if (orgs.length === 1) {
                                        dispatch(getOrgTeams(orgs?.[0]?.organization.slug)).then(
                                            (data, error) => {
                                                if (!error) setPrepareTeams(data.payload.data)
                                            }
                                        )
                                    }
                                    localStorage.setItem('org-manage-tab', 'members')
                                    history.push({
                                        pathname: `./organization/`,
                                        state: { orgList: prepareOrg, teams: prepareTeams },
                                    })
                                }}
                            >
                                <img
                                    src="/svgs/user-icon/setting.svg"
                                    alt="org-setting"
                                    style={{ marginRight: '8px' }}
                                ></img>
                                <span>Manage organisation</span>
                            </Button>
                        </Col>
                        <div
                            className="grey-600 text-center mx-auto mb-5 mt-4 size-14 weight-500 d-flex justify-content-center align-items-center"
                            onClick={() => {
                                const data = {
                                    user_default_organization: null,
                                }
                                dispatch(updateProfile(data))
                                setSelectOrg(false)
                                setAutoSelect(false)
                                setHasFoundOrg(false)
                            }}
                            style={{ width: '340px' }}
                        >
                            <img
                                src="/svgs/user-icon/arrow.svg"
                                style={{ cursor: 'pointer', marginRight: '8px' }}
                                alt="Logout"
                            ></img>
                            <span
                                style={{ cursor: 'pointer', lineHeight: '28px' }}
                                className="weight-500"
                            >
                                Change organisation
                            </span>
                        </div>
                    </Row>
                </Container>
            ) : (
                <Container
                    style={{ width: '500px', height: 'auto' }}
                    className="mx-auto my-5 d-flex flex-column align-items-center"
                >
                    <Row className="mt-3">
                        <span className="mx-auto">
                            <img
                                width="176px"
                                height="64px"
                                className="text-logo text-white animated-text"
                                src={'/svgs/user-icon/orglist.svg'}
                                alt="logo"
                            />
                        </span>
                    </Row>
                    <Row className="d-flex justify-content-center align-item-center">
                        <Col md={12}>
                            {orgs.list.length === 0 ? (
                                <h4 className="mb-5 text-center">
                                    You don't have any organization, please ask your admin to assign
                                    you to a organization
                                </h4>
                            ) : (
                                <div className="text-center my-4">
                                    <h2 className="size-32 py-32 text-black weight-500">
                                        Choose organisation
                                    </h2>
                                    <span className="size-16  mt-3 weight-400 grey-600">
                                        Please select your organisation
                                    </span>
                                </div>
                            )}
                            <RenderOrg orgs={orgs} />

                            <FormGroup check className="mt-5 text-center">
                                <Input
                                    type="checkbox"
                                    style={{ cursor: 'pointer' }}
                                    checked={autoSelect}
                                    onChange={() => {
                                        setAutoSelect(!autoSelect)
                                    }}
                                />{' '}
                                <Label check className="grey-900 weight-400 size-14 ">
                                    Automatically log in with this organisation next time
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="mx-auto text-center pt-32">
                        <Col
                            md={12}
                            className="d-flex flex-column justify-content-center align-items-center"
                        >
                            <Button
                                style={{ width: '340px', height: '40px' }}
                                className="save-btn-modal modals-new-btns mt-32"
                                disabled={!prepareOrg}
                                color="meili"
                                onClick={() => {
                                    const data = {
                                        user_default_organization: prepareOrg?.organization.uuid,
                                    }

                                    autoSelect && dispatch(updateProfile(data))
                                    if (prepareOrg) {
                                        dispatch(getOrgTeams(prepareOrg?.organization.slug))
                                        setSelectOrg(true)
                                    }
                                }}
                            >
                                Continue
                            </Button>

                            <div
                                className="grey-600 text-center mx-auto mb-5 mt-4 size-14 weight-500"
                                onClick={() => history.push('/logout/')}
                                style={{ width: '340px' }}
                            >
                                <img
                                    src="/svgs/user-icon/arrow.svg"
                                    style={{ cursor: 'pointer', paddingRight: '8px' }}
                                    alt="Logout"
                                ></img>
                                <span style={{ cursor: 'pointer', lineHeight: '28px' }}>
                                    Back to log in
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )}

            <Modal isOpen={versionModal} toggle={() => setVersionModal(!versionModal)} centered>
                <div className="text-center p-4 d-flex flex-column align-items-start">
                    <img
                        src="/svgs/modal-icons/refresh-page.svg"
                        alt="refresh"
                        width={48}
                        height={48}
                    />
                    <h3 className="text-center mt-4"> A new version is available.</h3>
                    <p className="text-center">Please refresh the page to get the latest update</p>

                    <Button
                        className="save-btn-modal modals-new-btns w-100 mt-4"
                        color="meili"
                        id="refresh-btn"
                        onClick={() => window.location.reload()}
                    >
                        Refresh page
                    </Button>
                    <Button
                        className="cancel-btn-modal modals-new-btns mt-2 w-100"
                        id="release-notes-btn"
                        onClick={() => history.push(`${path}organization/release_notes/`)}
                    >
                        View release notes
                    </Button>
                    <UncontrolledTooltip placement="top" target="refresh-btn">
                        If the button isn't working, try pressing Ctrl + Shift + R on Windows or Cmd
                        + Shift + R on Mac.
                    </UncontrolledTooltip>
                </div>
            </Modal>

            <span className="copyright-style">© Meili Robots 2024</span>
        </div>
    )
}

export default TeamSelect
