import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { selectors } from 'features/dashboard'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavItem, Popover } from 'reactstrap'
import { getOrgBySlug, getOrgs } from 'features/dashboard/Organization.actionTypes'
import NotificationCard from 'components/dashboard-components/NotificationsTitle'
import UserDashboardMenu from 'components/users/UserDashboardMenu'
import NotificationsWebSocket from 'components/utils/notificationsWebSocket'

const OrganizationNavbar = () => {
    const user = useSelector(selectors.getUser)
    const token = useSelector((state) => state.user.token)
    const dispatch = useDispatch()
    const { slug, title } = JSON.parse(localStorage.getItem('org'))
    const organization = useSelector(selectors.getOrg)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [notificationOpen, setNotification] = useState(false)
    const storedValueWSNotification = JSON.parse(localStorage.getItem('real_time_notification'))
    const [userMenu, setUserMenu] = useState(false)
    const toggleNotification = () => setNotification((prevState) => !prevState)
    const toggle = () => setDropdownOpen(!dropdownOpen)

    useEffect(() => {
        dispatch(getOrgs())
        dispatch(getOrgBySlug({ slug }))
    }, [slug]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="organization-navbar">
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                    <NavLink to="/dashboard/">
                        <img
                            src="/svgs/organization/navbar/purple-logo.svg"
                            alt="logo"
                            style={{ cursor: 'pointer' }}
                        />
                    </NavLink>
                    <Dropdown
                        isOpen={dropdownOpen}
                        toggle={toggle}
                        direction="down"
                        className="org-navbar-dropdown"
                    >
                        <DropdownToggle className="dropdown-toggle" caret>
                            {title}
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu">
                            {organization?.list?.map((org) => {
                                return (
                                    <DropdownItem
                                        key={org.organization.uuid}
                                        className="dropdown-item"
                                        style={{
                                            backgroundColor:
                                                org.organization.slug === slug ? '#f9fafb' : '',
                                        }}
                                        onClick={() => {
                                            localStorage.setItem(
                                                'org',
                                                JSON.stringify(org.organization)
                                            )
                                            window.location.reload()
                                        }}
                                    >
                                        {org.organization.title}
                                    </DropdownItem>
                                )
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div className="d-flex align-items-center" style={{ marginLeft: '48px' }}>
                    <NavLink
                        className="org-navbar-link"
                        to={`/dashboard/organization/`}
                        activeClassName="org-navbar-link-selected"
                        onClick={() => localStorage.setItem('org-manage-tab', 'members')}
                        exact
                    >
                        Members
                    </NavLink>
                    <NavLink
                        className="org-navbar-link"
                        to={`/dashboard/organization/vehicles/`}
                        activeClassName="org-navbar-link-selected"
                    >
                        Vehicles
                    </NavLink>
                    <NavLink
                        className="org-navbar-link"
                        to={`/dashboard/organization/device-models/`}
                        activeClassName="org-navbar-link-selected"
                    >
                        Device models
                    </NavLink>
                    <NavLink
                        className="org-navbar-link"
                        activeClassName="org-navbar-link-selected"
                        to={`/dashboard/organization/rossetup/`}
                    >
                        Fleet setups
                    </NavLink>
                    <NavLink
                        className="org-navbar-link"
                        to={`/dashboard/organization/settings/`}
                        activeClassName="org-navbar-link-selected"
                        exact
                    >
                        Settings
                    </NavLink>
                    {/* <NavLink
                        className="org-navbar-link"
                        activeClassName="org-navbar-link-selected"
                        to={`/dashboard/organization/wms-setup/?org=${orgSlug || ''}&team=${
                            teamSlug || ''
                        }`}
                    >
                        WMS Setup
                    </NavLink>
                    <NavLink
                        className="org-navbar-link"
                        activeClassName="org-navbar-link-selected"
                        to={`/dashboard/organization/manufacturer/?org=${orgSlug || ''}&team=${
                            teamSlug || ''
                        }`}
                    >
                        Manufacturers
                    </NavLink>
                    <NavLink
                        className="org-navbar-link"
                        activeClassName="org-navbar-link-selected"
                        to={`/dashboard/organization/actions/?org=${orgSlug || ''}&team=${
                            teamSlug || ''
                        }`}
                    >
                        Actions
                    </NavLink> */}
                </div>
            </div>
            <div className="d-flex">
                <NavItem className="px-lg-2 px-md-2 d-flex align-items-center">
                    <img
                        id="notifications"
                        src="/svgs/page-title-icons/notification.svg"
                        alt="notifications"
                        style={{ cursor: 'pointer' }}
                    />
                    {storedValueWSNotification && (
                        <NotificationsWebSocket token={token} toggle={toggleNotification} />
                    )}
                    <Popover
                        hideArrow={true}
                        popperClassName="notifications-popover"
                        target="notifications"
                        toggle={toggleNotification}
                        placement="left"
                        offset={[0, -15]}
                        trigger="legacy"
                        isOpen={notificationOpen}
                    >
                        <NotificationCard
                            toggle={toggleNotification}
                            notificationOpen={notificationOpen}
                        />
                    </Popover>
                </NavItem>
                <NavItem className="d-flex align-items-center" style={{ marginLeft: '16px' }}>
                    {user?.profile.image ? (
                        <div
                            className="rounded-circle"
                            style={{
                                width: '48px',
                                height: '48px',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                id="userMenu"
                                src={user.profile.image}
                                alt="user"
                                width={48}
                                height={48}
                                style={{
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer',
                                }}
                            />
                        </div>
                    ) : (
                        <img
                            id="userMenu"
                            src={'/svgs/page-title-icons/user.svg'}
                            alt="user"
                            width={48}
                            height={48}
                            style={{ cursor: 'pointer' }}
                        />
                    )}
                    <Popover
                        popperClassName="popover-full-width rounded border-0"
                        target="userMenu"
                        toggle={() => setUserMenu(!userMenu)}
                        placement="auto"
                        trigger="legacy"
                        isOpen={userMenu}
                    >
                        <UserDashboardMenu organizationSide={true} />
                    </Popover>
                </NavItem>
            </div>
        </div>
    )
}

export default OrganizationNavbar
